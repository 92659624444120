:root {
  --primary-color: #F1F0E8;
  --secondary-color: #c7af87;
  --tertiary-color: #96b6c5;
  --body-tertiary-color: #96b6c5
}

body, #root {
  
  background-color: #F1F0E8;
  color: black;
  font-family: 'Merriweather', serif;
  margin-bottom: 100px;
}


#header, footer {
  /* display: flex; */
  width: 100%;
}

#header {
  flex-shrink: 0;
  background-color: var(--tertiary-color);
  height: 100px;
  color: var(--primary-color);
  text-decoration: none;
}

footer {
  /* display: flex; */
  position: fixed;
  bottom: 0;
  left: 0;
  /* background-color: #4a86a1;
  color:  var(--secondary-color);
  height: 70px; */
}

h1{
  font-family: 'Kaushan Script', fantasy;
  display: flex;
  font-style: normal;
  font-weight: 400;
}
h2{
  font-family: 'Kiwi Maru', serif;   
  font-size: 40px; 
}

li, .spaced {
  margin: 20px;
}

#acronym {
  font-size: 96px;
  display: flex;
  width: 216px;
  height: 96px;
  flex-direction: column;
  justify-content: center;
  word-wrap: break-word;
  text-decoration: none;
  color: var(--primary-color);
}

#menu {
  display: flex;
  width: 64px;
  height: 64px;
  margin: 18px;
  flex-shrink: 0;
}

#company {
  margin-top: 40px;
  font-size: 24px;
  color:  var(--primary-color);
}

.bg-body-tertiary {
  background-color: #96b6c5;
  color: #96b6c5;
}

#copyright {
  margin: 30px;
}

#topOfPage {
  height: 40px;
  position: fixed;
  bottom: 25px;
  right: 15px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.dropdown {
  width: 100px;
  background: #ADC4CE;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background-color: #A1CAE2;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  position: absolute;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  text-decoration-line: none;
  text-align: left;
  display: block;
  padding: 12px 16px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.remove-decoration {
  text-decoration: none;
}

/* nav = #A1CAE2 */